.App {
  text-align: center;
}

.ant-layout {
  display: flex;
  flex-direction: column;
  flex: auto;
  background: #f0f2f5;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100vh;
}

.corona-info {
  position: sticky;
  top: 0;
  background-color: rgba(255, 204, 199,0.75);
  border: 1px solid rgb(245, 34, 45);
  z-index: 999;
  text-align: center;
}

.corona-info .ant-alert-message {
}
